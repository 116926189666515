import styled from "styled-components";
import { Colour } from "../../../../types";
import { SearchBar } from "../../search/search-bar";
import { Divider } from "../../divider";
import { ImageComponent } from "../../image-component-product/image-component";

export interface CategoryHeaderProps {
  subtitle: string;
  title: string;
  className?: string;
  headerImageUrl?: string;
  searchTerm?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  placeholderText: string;
}

const UnstyledCategoryHeader = ({
  className,
  title,
  subtitle,
  headerImageUrl,
  searchTerm,
  onChange,
  onSubmit,
  placeholderText
}: CategoryHeaderProps) => {
  return (
    <>
      <Divider />
      <div className={className}>
        <div className={`${className}-hero-image`}>
          <ImageComponent alt={title} href={headerImageUrl} />
        </div>
        <div className={`${className}-body`}>
          <h1>{title}</h1>
          <p>{subtitle}</p>
          <div className={`${className}-search-bar`}>
            <SearchBar
              placeholderText={placeholderText}
              onChange={onChange}
              onSubmit={onSubmit}
              searchTerm={searchTerm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const CategoryHeader = styled(UnstyledCategoryHeader)`
  position: relative;

  display: flex;
  background-color: ${Colour.BACKGROUND};
  color: ${Colour.WHITE};
  min-height: 240px;

  div[class$="hero-image"] {
    display: none;
  }

  div[class$="body"] {
    display: flex;
    padding: 16px 24px 30px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    text-align: center;
  }

  div[class$="search-bar"] {
    display: block;
    width: 700px;
  }

  h1 {
    border-color: ${Colour.ERROR};
    border-width: 5px 0;
    border-style: solid;
    font-family: FranklinGothicLTPro-DmCm;
    font-size: 38px;
    line-height: 1.11;
    margin-bottom: 0;
    padding-top: 8px;
    max-width: 950px;
  }

  g {
    fill: ${Colour.ERROR};
  }

  p {
    margin-top: 15px;
    margin-bottom: 25px;

    font-family: Roboto;
    font-size: 14px;
    line-height: 24px;
  }

  @media (min-width: 1024px) {
    display: flex;
    height: 350px;

    h1 {
      font-size: 58px;
      padding-top: 12px;
    }

    p {
      width: 650px;

      font-size: 16px;
    }

    div[class$="body"] {
      position: absolute;
    }

    div[class$="hero-image"] {
      display: unset;
      width: 100%;

      opacity: 0.5;
    }
  }

  @media (max-width: 768px) {
    div[class$="search-bar"] {
      width: 100%;
    }
  }
`;
