import React, { FormEvent } from "react";
import styled, { css } from "styled-components";
import { Field } from "../../../types";
import { SubscriptionSuccess } from "./subscription-success";

const links = {
  termsLink: "https://www.timeout.com/terms-of-use",
  privacyLink: "https://www.timeout.com/privacy-notice",
};

type Props = {
  emailField: Field;
  isSubscribed: boolean;
  termsLink?: string;
  privacyLink?: string;
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubscribe: (e: FormEvent<HTMLButtonElement>) => void;
};

const NewsletterWidget: React.FC<Props> = ({
  emailField,
  isSubscribed,
  termsLink = links.termsLink,
  privacyLink = links.privacyLink,
  onChangeEmail,
  onSubscribe,
}) => {
  return (
    <DIV>
      {!isSubscribed ? (
        <>
          <Title>Sign up to our newsletter</Title>

          <Content>
            <Input
              placeholder="Enter email"
              value={emailField.value}
              onChange={onChangeEmail}
            />
            <Button data-track={"subscribe"} onClick={onSubscribe}>
              Subscribe
            </Button>
          </Content>
          <Error>{emailField.error && "Please enter a valid email !"}</Error>
          <P>
            By subscribing you agree to our{" "}
            <A href={termsLink} target="_blank">
              Terms
            </A>{" "}
            and{" "}
            <A href={privacyLink} target="_blank">
              Privacy Notice
            </A>{" "}
            and consent to receive news, events, offers and partner promotions.
          </P>
        </>
      ) : (
        <SubscriptionSuccess />
      )}
    </DIV>
  );
};

const DIV = styled.div`
  position: relative;
  background-color: #1a1a1a;
  width: 100%;
  @media (max-width: 999px) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
`;

const Title = styled.p`
  color: #fff;
  font-family: FranklinGothicLTPro-DmCm;
  font-size: 28px;
  line-height: 32px;
  margin: 0 0 10px;
`;

const Content = styled.div`
  display: flex;
`;

const Input = styled.input`
  position: relative;
  width: 100%;
  height: 48px;
  background-color: #ffffff;
  padding: 13px 10px 11px 10px;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.05);
  font-size: 16px;
  font-family: Roboto;
  border: 0px solid;
  border-radius: 0px;
  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  background-color: #1a1a1a;
  padding: 12px 40px;
  border: solid 2px #ffffff;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto";
  @media (max-width: 999px) {
    padding: 12px 20px;
  }

  &:hover,
  &:focus {
    background-color: #d31a22 !important;
  }
`;

const P = styled.p`
  font-size: 12px;
  color: #aaaaaa;
  margin: 15px 0 0 0;
  font-family: "Roboto";
  line-height: 1.5;
  text-align: left;
`;

const Error = styled.p`
  font-size: 14px;
  color: #d31a22;
`;

const A = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: #aaaaaa;
`;

export default NewsletterWidget;
