export enum PageType {
  CHECKOUT = "checkout",
  DETAIL = "detail",
  EVENT = "event",
  HOME = "home",
  NOT_FOUND = "pagenotfound",
  SEARCH = "search",
  TICKET = "ticket",
  USE_CASE = "usecase",
}

export enum PageName {
  BOOKING_ERROR = "booking error",
  BOOKING_SUCCESS = "booking success",
  CHECKOUT_DETAILS = "checkout details",
  COLLECTION = "collection",
  DETAILS = "details",
  EVENT_DETAILS = "event details",
  HOME = "home",
  SEARCH = "search",
  TICKETS_LIST = "tickets list",
}

export enum MetaPixelEvent {
  ADD_TO_CART = "AddToCart",
  INITIATE_CHECKOUT = "InitiateCheckout",
  PAGE_VIEW = "PageView",
  PURCHASE = "Purchase",
}

export enum CustomGTMEvent {
  EC_ADD_TO_CART = "to.add_to_cart",
  EC_CHECKOUT = "to.checkout",
  EC_PRODUCT_CLICK = "to.product_click",
  EC_PRODUCT_VIEW = "to.product_view",
  EC_PROMOTION_CLICK = "to.promotion_click",
  EC_PURCHASE = "to.purchase",
  EC_REMOVE_FROM_CART = "to.remove_from_cart",
  TO_COOKIE_CONSENT = "to.cookie_consent",
  TO_NEWSLETTER_IN_VIEW = "to.newsletter_in_view",
  TO_NEWSLETTER_SIGN_UP = "to.newsletter_sign_up",
  TO_PAGE_VIEW = "to.page_view",
  TO_ATTEMPTED_TRANSACTIONS = "to.attempted_transactions",
  EC_IMPRESSIONS = "to.product_impression",
}

export const canaryCookieName = "_TO_Canary";
export const cookieConsent = "cookie-consent";
export const platform = "ecomm - v2 next.js";
export const section = "time out ecommerce";
