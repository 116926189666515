import React, { RefObject } from "react";
import styled from "styled-components";
import { LegalText } from "./legal-text";
import { Field } from "../../../types";
import { TimeOutLogo } from "../../../global/iconography/logos/logo";
import NewsletterWidget from "../newsletter-signup-widget/newsletter-widget";
import { SharingSitesLinksProps } from "./sharing-buttons";
import { FooterLinks } from "./footer-links";

export interface FooterProps {
  className?: string;
  footerLinksItems: Array<React.ReactElement>;
  shareLinksItems: SharingSitesLinksProps;
  legalText: string;
  newsLetterRef: RefObject<HTMLDivElement>;
  emailField: Field;
  isSubscribed: boolean;
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubscribe: (e: React.FormEvent<HTMLButtonElement>) => void;
  termsLink: string;
}

const footerUnstyled: React.FC<FooterProps> = ({
  className,
  emailField,
  footerLinksItems,
  isSubscribed,
  legalText,
  onChangeEmail,
  onSubscribe,
  newsLetterRef,
  termsLink,
}) => {
  return (
    <div className={className}>
      <div className={`${className}-logo-container`}>
        <TimeOutLogo />
        <div className={`${className}-links-sharing-container`}>
          <FooterLinks footerLinksItems={footerLinksItems} />
        </div>
        <LegalText text={legalText} />
      </div>
      <div id={"footer-newsletter"} ref={newsLetterRef}>
        <NewsletterWidget
          emailField={emailField}
          isSubscribed={isSubscribed}
          onChangeEmail={onChangeEmail}
          onSubscribe={onSubscribe}
          termsLink={termsLink}
        />
      </div>
    </div>
  );
};

export const Footer = styled(footerUnstyled)`
  background-color: #1a1a1a;
  padding: 0px 15px 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column-reverse;

  #footer-newsletter {
    width: 100%;
  }

  div[class$="links-sharing-container"] {
    padding-top: 30px;
  }

  img {
    height: 33px;
    width: 89px;
  }

  @media (min-width: 1000px) {
    justify-content: space-around;
    flex-direction: row;
    padding: 40px 80px 40px;

    #footer-newsletter {
      width: auto;
    }

    div[class$="links-sharing-container"] {
      display: flex;
    }
  }
`;
