import { useLazyQuery, useQuery } from "@apollo/client";
import { getClient } from "apollo-client";
import { Analytics, ErrorMessages, OptInDataSource } from "cf-constants";
import { MetaDataContext } from "cf-context";
import {
  AuthSession,
  Basket,
  Currency,
  CustomError,
  Device,
  EventGroup,
  EventPageMetaData,
  PaymentGateway,
  PurchaseType,
  ThirdPartyOptIn,
} from "cf-types";
import { gtmDataLayerPush, secureStorage } from "cf-utils";
import { useRouter } from "next/router";
import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import {
  BraintreeError,
  DeliveryAddressForm,
  ContactForm,
  Field,
  HostedFieldsEvent,
  OrderSummaryFooter,
  OrderSummaryWidget,
  PaymentForm,
  PaymentGatewayInstance,
} from "../../../src/components/to-components";
import { useDispatch, useSelector } from "react-redux";
import * as ReduxTypes from "redux/types";
import * as ReduxActions from "redux/actions";
import { initialState } from "redux/reducers/user-reducer";
import { initialState as billingInitialState } from "redux/reducers/checkout-reducer";
import {
  GetAuthUserProfileQuery,
  GetDeviceDataQuery,
  GetEventGroupQuery,
  PostBasketQuery,
} from "pages/api/queries";
import { AuthModal, CheckoutError } from "src/components";
import { formUtils, selectors } from "src/utils";
import { useRouterEvent } from "src/hooks";

import styles from "./checkout-review.module.scss";
import { GetThirdPartyOptInQuery } from "pages/api/queries/get-third-party-opt-in-query";
import { ApplePayPayload, ApplePaySession } from "braintree-web";
import { Event } from "../to-components/types";
import {
  permutivePostPaymentData,
  permutivePrePaymentData,
} from "cf-utils/analytics";

const hiddenFormKey = "hidden-booking-form";
const renderExtraFields = false; // As per what we discussed on stand up 22/12/21.
const thirdPartyDataConsent = "thirdPartyDataConsent";

declare global {
  interface Window {
    ApplePaySession: any;
    permutive: any;
  }
}

enum HostedFieldsErrors {
  HOSTED_FIELDS_FIELDS_EMPTY = "HOSTED_FIELDS_FIELDS_EMPTY",
  HOSTED_FIELDS_FIELDS_INVALID = "HOSTED_FIELDS_FIELDS_INVALID",
}

type ErrorHandlerStrategy = {
  dispatch: (
    p: ReduxActions.BulkUpdateAddress | ReduxActions.UpdateBillingAddress
  ) => void;
  error: BraintreeError;
};

const errorHandlerStrategy = {
  [HostedFieldsErrors.HOSTED_FIELDS_FIELDS_EMPTY]: ({
    dispatch,
  }: ErrorHandlerStrategy) => {
    const { cvv, expirationDate, number, postalCode } =
      billingInitialState.billing;
    dispatch(
      ReduxActions.updateBulkBillingAddress({
        fields: {
          cvv: { ...cvv, error: true },
          expirationDate: { ...expirationDate, error: true },
          number: { ...number, error: true },
          postalCode: { ...postalCode, error: true },
        },
      })
    );
  },
  [HostedFieldsErrors.HOSTED_FIELDS_FIELDS_INVALID]: ({
    dispatch,
    error,
  }: ErrorHandlerStrategy) => {
    error.details.invalidFieldKeys.forEach(
      (field: ReduxTypes.SecurePaymentFields) => {
        dispatch(
          ReduxActions.updateBillingAddress({
            field,
            validator: () => false,
          })
        );
      }
    );
  },
};

const CheckoutReview: React.FC<
  Pick<PaymentGateway, "clientToken"> & {
    devURL: string;
    deviceId: string;
    eventData: EventPageMetaData & {
      currency: Currency;
      currencyCode: keyof typeof Currency;
      events: Event[];
      eventGroupID: number;
      eventType: EventGroup["eventType"];
      purchaseType: PurchaseType;
      shippable: boolean;
      termsUrl?: string;
      title: string;
    };
  }
> = ({ clientToken, deviceId, devURL, eventData }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [promocode, setPromocode] = useState<string>("");
  const [is3dsEnabled] = useState<boolean>(true);
  const hiddenForm = useRef<HTMLFormElement>(null);
  const [paymentGateway, setPaymentGateway] =
    useState<PaymentGatewayInstance>();
  const [isApplePay, setApplePay] = useState<boolean>(false);
  const [applePayInstance, setApplePayInstance] = useState<any>(null);
  const [, setMetaData] = useContext(MetaDataContext);
  const {
    auth,
    basket,
    billing,
    booking,
    delivery,
    optInData,
    pageName,
    selectedMarket,
    supportEmail,
    user,
  } = useSelector(selectors.checkoutReview);
  const [currentRoute, setCurrentRoute] = useState<string>(router.asPath);
  const { categories, localeName, pages, slug } = selectedMarket[0];

  const onRouteChangeStart = (url: string) => {
    setCurrentRoute(url);
  };

  const optInDataList = [...optInData];
  if (booking.thirdPartyOptIn && booking.thirdPartyOptIn.isEnabled) {
    optInDataList.push({
      value: booking.thirdPartyDataShareConsent,
      field: thirdPartyDataConsent,
      text: booking.thirdPartyOptIn.frontendDisplayLabel,
    });
  }

  useRouterEvent("routeChangeStart", onRouteChangeStart, [pageName]);

  const { eventGroupID } = eventData;

  const [fetchDeviceData] = useLazyQuery<{
    getDeviceData: Device;
  }>(GetDeviceDataQuery, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getDeviceData }) => {
      dispatch(
        ReduxActions.setDeviceData({
          deviceId: getDeviceData.deviceId,
          supportEmail: getDeviceData.selectedMarket?.supportEmail || "",
        })
      );
    },
    onError: console.error,
  });

  const [postBasket] = useLazyQuery<{ postBasket: Basket }>(PostBasketQuery, {
    fetchPolicy: "no-cache",
    onCompleted: ({ postBasket }) => {
      dispatch(
        ReduxActions.setBasket({
          basket: postBasket,
        })
      );
    },
    onError: console.error,
  });

  const { data: eventGroup } = useQuery<{ getEventGroupDetails: EventGroup }>(
    GetEventGroupQuery,
    {
      skip: !!eventGroupID,
      variables: {
        eventGroupData: JSON.stringify({
          deviceId,
          eventGroupID,
        }),
      },
    }
  );

  useEffect(() => {
    if (eventGroup) {
      setMetaData(eventData);
    }
  }, [eventData]);

  useEffect(() => {
    (async () => {
      const client = getClient(devURL);
      const { data } = await client.query<{
        getThirdPartyOptIn: ThirdPartyOptIn;
      }>({
        query: GetThirdPartyOptInQuery,
        variables: {
          eventGroupData: JSON.stringify({
            deviceId,
            eventGroupID,
          }),
        },
      });
      if (data.getThirdPartyOptIn.isEnabled) {
        dispatch(
          ReduxActions.setThirdPartyDataOptIn({
            thirdPartyOptIn: {
              isEnabled: true,
              frontendDisplayLabel:
                data.getThirdPartyOptIn.frontendDisplayLabel,
            },
          })
        );
      }
    })();
  }, []);

  useEffect(() => {
    if (eventGroup) {
      const { currencyCode, images, name, purchaseType, shippable } =
        eventGroup.getEventGroupDetails;

      dispatch({
        payload: {
          eventGroupID,
          currencyCode,
          imageSrc: images[0].url,
          purchaseType,
          shippable,
          title: name,
        },
        type: ReduxTypes.UPDATE_EVENT_DATA,
      });
    }
  }, [eventGroup]);

  useEffect(() => {
    if (paymentGateway) {
      const { environment, merchantId } =
        paymentGateway?.configuration.gatewayConfiguration;
      window.BraintreeData.setup(
        merchantId,
        hiddenFormKey,
        window.BraintreeData.environments[environment]
      );
    }
  }, [paymentGateway]);

  useEffect(() => {
    const getApiKey = () => {
      const { api_key } = JSON.parse(localStorage.getItem("login") || "{}");
      return api_key;
    };

    fetchDeviceData({
      variables: {
        deviceData: JSON.stringify({
          authorization: auth.loggedIn ? getApiKey() : "",
          deviceId,
        }),
      },
    });
  }, [auth.loggedIn, fetchDeviceData]);

  useEffect(() => {
    if (!auth.loggedIn) {
      return;
    }

    (async () => {
      try {
        const access_token = window.localStorage.getItem("access_token");
        const client = getClient(devURL);

        const { data } = await client.query<{
          getAuthUserProfile: AuthSession;
        }>({
          query: GetAuthUserProfileQuery,
          variables: {
            authData: JSON.stringify({
              access_token,
              deviceId,
            }),
          },
        });

        if (!data.getAuthUserProfile) {
          const error = {
            message: ErrorMessages.Checkout.GET_AUTH_USER_PROFILE,
          };

          dispatch(
            ReduxActions.renderModal({
              component: (
                <CheckoutError
                  buttonTitle={"LOG IN"}
                  error={error as CustomError}
                  supportEmail={supportEmail}
                  onClick={() => {
                    dispatch(ReduxActions.logOut());
                    dispatch(
                      ReduxActions.renderModal({
                        component: <AuthModal />,
                        render: true,
                      })
                    );
                  }}
                />
              ),
              onClose: () => {
                dispatch(ReduxActions.logOut());
              },
              render: true,
            })
          );
        }
      } catch (e) {
        const err = e as Error;
        const error = {
          message: ErrorMessages.Checkout.GET_AUTH_USER_PROFILE,
          name: err.name,
        };
        dispatch(
          ReduxActions.renderModal({
            component: (
              <CheckoutError
                buttonTitle={"LOG IN"}
                error={error}
                supportEmail={supportEmail}
                onClick={() => {
                  dispatch(ReduxActions.logOut());
                  dispatch(
                    ReduxActions.renderModal({
                      component: <AuthModal />,
                      render: true,
                    })
                  );
                }}
              />
            ),
            onClose: () => {
              dispatch(ReduxActions.logOut());
            },
            render: true,
          })
        );
      }
    })();
  }, [auth.loggedIn]);

  const basketIsNotEmpty = basket.length >= 1;

  useEffect(() => {
    basketIsNotEmpty &&
      postBasket({
        variables: {
          basket: JSON.stringify({
            deviceId,
            promoCode: null,
            ticket_groups: basket.map(({ item, quantity }) => {
              return {
                event: item.resourceUri,
                ticket_count: quantity,
              };
            }),
          }),
        },
      });
  }, [basketIsNotEmpty, postBasket]);

  useEffect(() => {
    try {
      const localBasket =
        secureStorage.getDecryptedItem<
          ReduxTypes.Store["BasketItems"]["items"]
        >("basket");

      if (!basketIsNotEmpty && localBasket) {
        gtmDataLayerPush({ ecommerce: null, event: null });
        gtmDataLayerPush(
          {
            event: Analytics.CustomGTMEvent.EC_CHECKOUT,
            ecommerce: {
              currency: eventData.currencyCode,
              value: localBasket.reduce(
                (acc, { item, quantity }) =>
                  acc + item.priceWithoutFee * quantity,
                0
              ),
              items: localBasket.map(({ item, quantity }) => ({
                item_id: `${eventGroupID}`,
                item_name: eventData.title.toLowerCase(),
                price: item.priceWithoutFee,
                quantity,
                item_variant: eventData.purchaseType,
                item_category: eventData.eventType.title.toLowerCase(),
                item_category2: eventData.purchaseType,
                item_category3: eventData.title.toLowerCase(),
                item_category4: item.seatDescription.toLowerCase(),
              })),
            },
          },
          categories,
          route
        );
      }
    } catch (error) {}
  }, [basket.length]);

  useEffect(() => {
    try {
      const localBasket =
        secureStorage.getDecryptedItem<
          ReduxTypes.Store["BasketItems"]["items"]
        >("basket");
      if (!basketIsNotEmpty && localBasket) {
        dispatch({
          payload: localBasket,
          type: ReduxTypes.UPDATE_BASKET_ITEMS,
        });
      }
    } catch (error) {}
  }, [basket.length]);

  useEffect(() => {
    const { email, firstName, lastName, phone } = auth;

    if (email || firstName || lastName || phone) {
      dispatch(
        ReduxActions.updateBulkUser({
          fields: {
            email: { ...initialState.email, value: email },
            firstName: { ...initialState.firstName, value: firstName },
            lastName: { ...initialState.lastName, value: lastName },
            phone: { ...initialState.phone, value: phone },
          },
        })
      );
    }
  }, [auth.email, auth.firstName, auth.lastName, auth.phone]);

  useEffect(() => {
    const { latestOffers } = auth;

    if (typeof latestOffers === "boolean") {
      dispatch(
        ReduxActions.updateOptIn({
          field: OptInDataSource.latestOffers.field,
          value: auth.latestOffers,
        })
      );
    }
  }, [auth.latestOffers]);

  useEffect(() => {
    const handleValidityChange = ({ emittedBy, fields }: HostedFieldsEvent) => {
      dispatch(
        ReduxActions.updateBillingAddress({
          field: emittedBy as ReduxTypes.SecurePaymentFields,
          validator: () => fields[emittedBy].isValid,
        })
      );
    };
    paymentGateway?.onEvent("validityChange", handleValidityChange);

    return () => {
      paymentGateway?.offEvent("validityChange");
    };
  }, [paymentGateway]);

  useEffect(() => {
    try {
      const localBasket =
        secureStorage.getDecryptedItem<
          ReduxTypes.Store["BasketItems"]["items"]
        >("basket");
      if (
        !basketIsNotEmpty &&
        document?.URL &&
        (!localBasket || !localBasket?.length)
      ) {
        router.push(document.URL.substring(0, document.URL.lastIndexOf("/")));
      }
    } catch (error) {}
  }, [basketIsNotEmpty]);

  useEffect(() => {
    const { error } = booking;

    if (!error) {
      return;
    }

    const onClick = () => {
      dispatch(ReduxActions.dismissModal());
      onSubmit();
    };

    dispatch({
      type: ReduxTypes.UPDATE_PAGE_DATA,
      payload: { pageName: Analytics.PageName.BOOKING_ERROR },
    });

    dispatch(
      ReduxActions.renderModal({
        component: (
          <CheckoutError
            buttonTitle={error?.buttonTitle}
            error={error}
            supportEmail={supportEmail}
            onClick={error.onClose ?? onClick}
          />
        ),
        render: true,
      })
    );

    gtmDataLayerPush(
      {
        event: Analytics.CustomGTMEvent.TO_ATTEMPTED_TRANSACTIONS,
        payload: {
          errorCategory: `[${ErrorMessages.Checkout.API_ERROR}]`,
          errorMessage: error.message,
          pageName: `${Analytics.section} : ${Analytics.PageName.BOOKING_ERROR}`,
          pageType: `${Analytics.PageType.CHECKOUT} pages`,
        },
      },
      categories,
      route
    );
  }, [booking.error]);

  useEffect(() => {
    return () => {
      dispatch(ReduxActions.resetError());
    };
  }, []);

  const route = {
    query: {
      event: router.query?.event,
      market: slug,
    },
    url: currentRoute,
  };

  useEffect(() => {
    const promocodeDetails = getPromocodeDetails();

    if (promocodeDetails && !promocodeDetails?.error) {
      gtmDataLayerPush({ ecommerce: null });
      gtmDataLayerPush(
        {
          event: Analytics.CustomGTMEvent.EC_PROMOTION_CLICK,
          ecommerce: {
            promotion_name: promocodeDetails.name.toLowerCase(),
          },
        },
        categories,
        route
      );
    }
  }, [booking?.basket?.promoCode]);

  useEffect(() => {
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      window.braintree.client
        .create({
          authorization: clientToken,
        })
        .then(function (clientInstance) {
          return window.braintree.applePay.create({
            client: clientInstance,
          });
        })
        .then(function (applePayInstance) {
          setApplePay(true);
          setApplePayInstance(applePayInstance);
        })
        .catch(function (err) {
          // Handle error
        });
    }
  }, [isApplePay]);

  useEffect(() => {
    window.permutive.addon(
      "web",
      permutivePrePaymentData(eventData, basket, calculateTotalPrice())
    );
  }, [eventData]);

  const onApplePaySubmit = (e: FormEvent) => {
    e.preventDefault();

    const { shippable } = eventData;
    const totalPrice = calculateTotalPrice();
    const isFree = totalPrice === 0;

    if (!applePayInstance || isFree) {
      return;
    }

    const paymentRequest = applePayInstance.createPaymentRequest({
      total: {
        label: eventData.title,
        amount: totalPrice,
      },
      currencyCode: eventData.currencyCode,

      requiredBillingContactFields: ["email", "phone", "name", "postalAddress"],
      requiredShippingContactFields: ["email"],
    });

    const session = new window.ApplePaySession(3, paymentRequest);

    session.onvalidatemerchant = function (
      event: ApplePayJS.ApplePayValidateMerchantEvent
    ) {
      applePayInstance
        .performValidation({
          validationURL: event.validationURL,
          displayName: "Checkout",
        })
        .then(function (
          merchantSession: ApplePayJS.ApplePayValidateMerchantEvent
        ) {
          session.completeMerchantValidation(merchantSession);
        })
        .catch(function (validationErr: ApplePayJS.ApplePayError) {
          console.error("Error validating merchant:", validationErr);
          session.abort();
        });
    };

    session.onpaymentauthorized = function (
      event: ApplePayJS.ApplePayPaymentAuthorizedEvent
    ) {
      const {
        givenName,
        familyName,
        phoneNumber,
        addressLines,
        locality,
        postalCode,
        countryCode,
      } = event.payment?.billingContact || {};
      const { emailAddress } = event.payment?.shippingContact || {};

      const userFields = {
        email: {
          error: !emailAddress,
          required: true,
          value: emailAddress!,
        },
        firstName: {
          error: !givenName,
          required: true,
          value: givenName!,
        },
        lastName: {
          error: !familyName,
          required: true,
          value: familyName!,
        },
        phone: {
          error: !phoneNumber,
          required: false,
          value: phoneNumber || "",
        },
      };

      const deliveryFields = {
        address: {
          error: !addressLines,
          required: true,
          value: addressLines?.join(", ")!,
        },
        recipientName: {
          error: !givenName || !familyName,
          required: true,
          value: `${givenName} ${familyName}`!,
        },
        state: {
          error: !countryCode,
          required: true,
          value: countryCode!,
        },
        townCity: {
          error: !locality,
          required: true,
          value: locality!,
        },
        zipcode: {
          error: !postalCode,
          required: true,
          value: postalCode!,
        },
      };

      applePayInstance
        .tokenize({
          token: event.payment.token,
        })
        .then(function (payload: ApplePayPayload) {
          const userFieldsCheck = formUtils.validateUser(userFields);
          const deliveryFieldsCheck =
            formUtils.validateDelivery(deliveryFields);

          dispatch(
            ReduxActions.updateBulkUser({
              fields: userFieldsCheck,
            })
          );

          if (shippable) {
            ReduxActions.updateBulkDeliveryAddress({
              fields: deliveryFieldsCheck,
            });
          }

          dispatch(
            ReduxActions.setIsLoading({
              isLoading: true,
            })
          );

          const { value } = hiddenForm.current
            ?.children?.[0] as HTMLInputElement;

          dispatch(ReduxActions.setPaymentToken(payload.nonce));
          dispatch(
            ReduxActions.startBookingProcess(devURL, {
              totalPrice,
              tracking: {
                eventData,
                route,
              },
              applePayNonce: payload.nonce,
              ...JSON.parse(value || "{}"),
            })
          );
          window.permutive.addon(
            "web",
            permutivePostPaymentData(
              eventData,
              basket,
              calculateTotalPrice(),
              getPromocodeDetails()
            )
          );
          session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
        })
        .catch((error: BraintreeError) => {
          dispatch(
            ReduxActions.setIsLoading({
              isLoading: false,
            })
          );
          if (error.type === "CUSTOMER") {
            errorHandlerStrategy[error.code as HostedFieldsErrors]({
              dispatch,
              error,
            });
          } else {
            dispatch(
              ReduxActions.renderModal({
                component: (
                  <CheckoutError error={error} supportEmail={supportEmail} />
                ),
                render: true,
              })
            );
          }
        })
        .catch(function (tokenizeErr: ApplePayError) {
          console.error("Error tokenizing Apple Pay:", tokenizeErr);
          session.completePayment(window.ApplePaySession.STATUS_FAILURE);
        });
    };

    session.begin();
  };

  const onSubmit = async () => {
    const { shippable } = eventData;
    const totalPrice = calculateTotalPrice();
    const isFree = totalPrice === 0;

    const userFieldsCheck = formUtils.validateUser(user);
    const billlingFieldsCheck = formUtils.validateBilling(billing, {
      is3dsEnabled,
      renderExtraFields,
    });
    const deliveryFieldsCheck = formUtils.validateDelivery(delivery);

    const requiredFields = Object.assign(
      {},
      !isFree ? billlingFieldsCheck : {},
      shippable ? deliveryFieldsCheck : {},
      userFieldsCheck
    );
    const { field, isRequiredFieldsValid } = Object.entries<Field>(
      requiredFields
    )
      .slice(0)
      .reduce<{
        isRequiredFieldsValid: boolean;
        field: string;
      }>(
        (acc, [name, { error }], _, arr) => {
          if (error === true) {
            arr.splice(1); // eject early from reduce
          }

          acc = {
            field: name,
            isRequiredFieldsValid: acc.isRequiredFieldsValid && !error,
          };
          return acc;
        },
        { field: "", isRequiredFieldsValid: true }
      );

    dispatch(
      ReduxActions.updateBulkUser({
        fields: userFieldsCheck,
      })
    );

    !isFree &&
      dispatch(
        ReduxActions.updateBulkBillingAddress({
          fields: billlingFieldsCheck,
        })
      );

    shippable &&
      dispatch(
        ReduxActions.updateBulkDeliveryAddress({
          fields: deliveryFieldsCheck,
        })
      );

    if (isFree && isRequiredFieldsValid) {
      dispatch(
        ReduxActions.setIsLoading({
          isLoading: true,
        })
      );
      dispatch(ReduxActions.startBookingProcess(devURL));
    }

    if (!isFree && !isRequiredFieldsValid) {
      gtmDataLayerPush(
        {
          event: Analytics.CustomGTMEvent.TO_ATTEMPTED_TRANSACTIONS,
          payload: {
            errorCategory: `[${ErrorMessages.Checkout.REQUIRED_FIELD_VALIDATION_ERROR}]`,
            errorMessage: field,
          },
        },
        categories,
        route
      );
    }

    if (!isFree && isRequiredFieldsValid && paymentGateway) {
      const paymentOptions = is3dsEnabled
        ? {
            cardholderName: billing.cardholderName.value,
            ...(renderExtraFields
              ? {
                  billingAddress: {
                    locality: billing.townCity.value,
                    streetAddress: billing.billingAddress.value,
                  },
                }
              : {}),
          }
        : {};

      dispatch(
        ReduxActions.setIsLoading({
          isLoading: true,
        })
      );

      paymentGateway
        ?.tokenize(booking.basket.chargeAmount, paymentOptions)
        .then((res) => {
          const { value } = hiddenForm.current
            ?.children?.[0] as HTMLInputElement;

          dispatch(ReduxActions.setPaymentToken(res));
          window.permutive.addon(
            "web",
            permutivePostPaymentData(
              eventData,
              basket,
              calculateTotalPrice(),
              getPromocodeDetails()
            )
          );
          dispatch(
            ReduxActions.startBookingProcess(devURL, {
              totalPrice,
              tracking: {
                eventData,
                route,
              },
              ...JSON.parse(value || "{}"),
            })
          );
        })
        .catch((error: BraintreeError) => {
          dispatch(
            ReduxActions.setIsLoading({
              isLoading: false,
            })
          );
          if (error.type === "CUSTOMER") {
            errorHandlerStrategy[error.code as HostedFieldsErrors]({
              dispatch,
              error,
            });
          } else {
            dispatch(
              ReduxActions.renderModal({
                component: (
                  <CheckoutError error={error} supportEmail={supportEmail} />
                ),
                render: true,
              })
            );
          }

          gtmDataLayerPush(
            {
              event: Analytics.CustomGTMEvent.TO_ATTEMPTED_TRANSACTIONS,
              payload: {
                errorCategory: `[${error.code}]`,
                errorMessage: error.message,
              },
            },
            categories,
            route
          );
        });
    }
  };

  const signInCB = (e: React.FormEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(
      ReduxActions.renderModal({
        component: <AuthModal />,
        render: true,
      })
    );
  };

  const handleContact =
    (field: ReduxTypes.ContactFields) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(ReduxActions.updateUser({ field, value: target.value }));
    };

  const handleEditDetails = (event: React.FormEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    router.push("/me/details");
  };

  const handleDeliveryAddress =
    (field: ReduxTypes.DeliveryAddressFields) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        ReduxActions.updateDeliveryAddress({ field, value: target.value })
      );
    };

  const optInActionMap = {
    [thirdPartyDataConsent]: ({
      target,
    }: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        target.checked
          ? ReduxActions.setThirdPartyDataShareConsent()
          : ReduxActions.resetThirdPartyDataShareConsent()
      );
    },
    [OptInDataSource.latestOffers.field]: ({
      target,
    }: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        ReduxActions.updateOptIn({ field: target.name, value: target.checked })
      );
    },
  };

  const handleOptIn = (event: React.ChangeEvent<HTMLInputElement>) => {
    optInActionMap[event.target.name](event);
  };

  const handlePayment =
    (field: ReduxTypes.PaymentFields) =>
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        ReduxActions.updateBillingAddress({ field, value: target.value })
      );
    };

  const handlePromocodeSubmit = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    postBasket({
      variables: {
        basket: JSON.stringify({
          deviceId,
          promo_code: promocode,
          ticket_groups: basket.map(({ item, quantity }) => ({
            event: item.resourceUri,
            ticket_count: quantity,
          })),
        }),
      },
    });
  };

  const handlePromoCode = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setPromocode(target.value);
  };

  const handleEditBasket = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    router.back();
  };

  const handleResetPromocode = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    postBasket({
      variables: {
        basket: JSON.stringify({
          deviceId,
          promo_code: null,
          ticket_groups: basket.map(({ item, quantity }) => ({
            event: item.resourceUri,
            ticket_count: quantity,
          })),
        }),
      },
    });
  };
  const getPromocodeDetails = () => {
    if (!booking?.basket) {
      return null;
    }

    const { paymentAmount, promoCode } = booking.basket;

    return promoCode
      ? {
          discountAmount: promoCode.amount,
          discountPercentage: Math.floor(
            (promoCode.amount / paymentAmount) * 100
          ),
          error: promoCode.error,
          name: promoCode.name,
        }
      : null;
  };

  const calculateTotalPrice = () => {
    const promocodeDetails = getPromocodeDetails();

    const totalBeforeDiscount = basket.reduce((total, { item, quantity }) => {
      const { bookingFee, priceWithoutFee } = item;
      const price = priceWithoutFee * quantity;

      if (bookingFee) {
        total += price + bookingFee * quantity;
        return total;
      }

      total += price;
      return total;
    }, 0);

    return promocodeDetails
      ? totalBeforeDiscount - promocodeDetails.discountAmount
      : totalBeforeDiscount;
  };

  const price = calculateTotalPrice();
  const isFree = price === 0;
  const totalPrice = isFree
    ? "Free"
    : `${eventData.currency}${price.toFixed(2)}`;

  return (
    <div className={styles.root}>
      <div className={styles.forms}>
        <ContactForm
          displayHeader={!auth.loggedIn}
          email={user.email}
          firstName={user.firstName}
          lastName={user.lastName}
          loggedIn={auth.loggedIn}
          phone={user.phone}
          onChangeEmail={handleContact("email")}
          onChangeFirstName={handleContact("firstName")}
          onChangeLastName={handleContact("lastName")}
          onChangePhone={handleContact("phone")}
          onEditDetails={handleEditDetails}
          signInCB={signInCB}
        />
        {eventData.shippable && (
          <DeliveryAddressForm
            address={delivery.address}
            onChangeAddress={handleDeliveryAddress("address")}
            onChangeRecipientName={handleDeliveryAddress("recipientName")}
            onChangeState={handleDeliveryAddress("state")}
            onChangeTownCity={handleDeliveryAddress("townCity")}
            onChangeZipcode={handleDeliveryAddress("zipcode")}
            recipientName={delivery.recipientName}
            state={delivery.state}
            townCity={delivery.townCity}
            zipcode={delivery.zipcode}
          />
        )}
        {!isFree && (
          <PaymentForm
            authorization={clientToken}
            billingAddress={billing.billingAddress}
            cardholderName={billing.cardholderName}
            cvv={billing.cvv}
            expirationDate={billing.expirationDate}
            isApplePay={isApplePay}
            isShippable={renderExtraFields}
            number={billing.number}
            onApplePaySubmit={onApplePaySubmit}
            onChangeCardholderName={handlePayment("cardholderName")}
            onChangeTownCity={handlePayment("townCity")}
            onChangeState={handlePayment("state")}
            onChangeBillingAddress={handlePayment("billingAddress")}
            onPaymentGateway={setPaymentGateway}
            postalCode={billing.postalCode}
            townCity={billing.townCity}
            state={billing.state}
            staticTexts={{
              postalCodeLabel: `${localeName === "en_US" ? "Zip" : "Post"}code`,
            }}
            is3dsSecEnabled={is3dsEnabled}
          />
        )}
        <div className={styles.submit}>
          <OrderSummaryFooter
            privacyNoticeUrl={pages.privacyPolicy}
            termsUrl={pages.termsAndConditions}
            shopTermsUrl={pages.checkoutTerms}
            onClick={onSubmit}
            totalPrice={totalPrice}
          />
        </div>
      </div>
      <aside className={styles.aside}>
        <div className={styles.summary}>
          <OrderSummaryWidget
            currency={eventData.currency}
            onEditBasket={handleEditBasket}
            imageSrc={eventData.images?.[0]?.url}
            onSubmit={onSubmit}
            items={basket}
            promocode={promocode}
            promocodeError={!!booking.basket?.promoCode?.error}
            promocodeDetails={getPromocodeDetails()}
            purchaseType={eventData.purchaseType}
            onChangeOptIn={handleOptIn}
            onPromocode={handlePromoCode}
            onPromocodeSubmit={handlePromocodeSubmit}
            onResetPromocode={handleResetPromocode}
            optInData={optInDataList}
            privacyNoticeUrl={pages.privacyPolicy}
            termsUrl={pages.termsAndConditions}
            title={eventData.title}
            totalPrice={totalPrice}
            shopTermsUrl={pages.checkoutTerms}
          />
        </div>
      </aside>
      <form className={styles.hidden} id={hiddenFormKey} ref={hiddenForm} />
    </div>
  );
};

export default CheckoutReview;
