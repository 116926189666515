import { useRouter } from "next/router";
import { useEffect } from "react";

interface Link {
  action: (url: string) => void;
  matcher: (url: string) => boolean;
}

const matchPath = (currentPath: string, links: Link[]) => {
  links.forEach(({ action, matcher }) => {
    matcher(currentPath) && action(currentPath);
  });
};

export const useDeepLink = (links: Link[]) => {
  const { asPath } = useRouter();

  useEffect(() => {
    matchPath(asPath, links);
  }, [asPath, links]);
};
